import Breakpoints from '@utils/breakpoints'
import { Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'

export type ClassType = {
	section: string
	container: string
	column1: string
	column2: string
	secondaryColorBox: string
	buttonOutline: string
	box: string
	boxQuestion: string
	icon: string
	card: string
	iconContainer: string
}

export const classes: ClassType = makeClasses({
	section: {
		margin: '50px',
		display: 'block',
		[Breakpoints.maxWidth('sm')]: {
			margin: '50px 20px'
		}
	},
	container: {
		display: 'grid',
		gridTemplateColumns: '2fr 1fr',
		gridAutoRows: 'minmax("fit-content", auto)',
		gridGap: '20px',
		transition: '0.3s',
		[Breakpoints.maxWidth('xl')]: {
			gridTemplateColumns: 'repeat(1, 1fr)'
		}
	},
	column1: {
		display: 'grid',
		gridTemplateColumns: 'repeat(3, 1fr)',
		gridAutoRows: 'minmax(fit-content, auto)',
		gridGap: '15px',
		transition: '0.3s',
		[Breakpoints.maxWidth('sm')]: {
			gridTemplateColumns: 'repeat(2, 1fr)'
		},
		[Breakpoints.maxWidth('xl')]: {
			gridRow: '1/2'
		}
	},
	column2: {
		[Breakpoints.maxWidth('xl')]: {
			gridRow: '2/2'
		}
	},
	secondaryColorBox: {
		fontWeight: 600,
		background: Colors.secondary,
		color: Colors.white,
		padding: '20px 30px'
	},
	box: {
		padding: 0,
		marginBottom: '30px !important',
		background: Colors.white,
		margin: '10px 0 10px',
		transition: '0.3s'
	},
	buttonOutline: {
		border: `1px solid ${Colors.white}`,
		color: `${Colors.white} !important`,
		background: 'transparent',
		padding: '15px 30px',
		fontSize: '16px',
		borderRadius: '23px',
		transition: 'all 0.35s ease',
		display: 'inline-block',
		fontWeight: 500,
		lineHeight: '1em',
		cursor: 'pointer',
		textDecoration: 'none',
		':hover': {
			borderColor: Colors.darkBlue2,
			background: Colors.darkBlue2
		}
	},
	boxQuestion: {
		fontWeight: 'normal',
		padding: '40px 50px',
		fontSize: '22px',
		'& > p': {
			margin: '0 0 1rem 0'
		}
	},
	icon: {
		width: '50px',
		marginBottom: '20px',
		[Breakpoints.maxWidth('sm')]: {
			width: '40px'
		}
	},
	card: {
		background: Colors.white,
		display: 'grid',
		gridTemplateColumns: '1fr',
		justifyContent: 'center',
		alignItems: 'flex-start',
		flexDirection: 'column',
		textAlign: 'center',
		padding: '30px 25px',
		transition: '0.3s',
		lineHeight: '1.4',
		fontSize: '20px',
		fontWeight: 500,
		color: Colors.black,
		[Breakpoints.maxWidth('sm')]: {
			fontSize: '16px'
		},
		':hover': {
			background: Colors.secondary,
			color: Colors.white,
			cursor: 'pointer',
			'& a': {
				textDecoration: 'none !important',
				color: `${Colors.white} !important`
			}
		},
		'& a': {
			textDecoration: 'none !important',
			color: `${Colors.black} !important`,
			transition: '0.3s'
		},
		'& p': {
			marginTop: '0'
		}
	},
	iconContainer: {
		paddingTop: '25px'
	}
})
