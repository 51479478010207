import React, { FC, useContext, useEffect } from 'react'
import { PageTitle } from '@components/configs/PageTitle'
import SectionTitle from '@components/ui/sectionTitle'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import {
	formatStrapiText,
	onNavigateToOtherSubjectRequest
} from '@utils/methods'
import * as pageUtils from '@pages/online-services/__index.utils'
import BlueBulletsList from '@components/ui/blueBulletsList'
import Text from '@components/ui/text'
import {
	abonnementIcon,
	activityGrayIcon,
	constatInfractionIcon,
	evalutationIcon,
	permisIcon
} from '@images/icons'
import { Icon } from '@components/ui/Icon'
import Link from '@components/ui/link'

const OnlineService: FC = () => {
	const { pageData } = useContext(AppStateContext)

	const labels = [
		{ name: pageData.assets.label_city_council },
		{ name: pageData.assets.label_social_media },
		{ name: pageData.assets.label_dynamic_map },
		{ name: pageData.assets.label_ombudsman },
		{ name: pageData.assets.label_media_relations },
		{ name: pageData.assets.label_publications }
	]

	const cards = [
		{
			title:
				pageData.assets.OnlineServicePage_consult_assessment_role_and_taxation,
			icon: evalutationIcon
		},
		{
			title: pageData.assets.OnlineServicePage_bylaws_and_municipal_permits,
			icon: permisIcon
		},
		{
			title:
				pageData.assets.OnlineServicePage_payment_of_permit_and_certificates,
			icon: permisIcon
		},
		{
			title: pageData.assets.OnlineServicePage_pay_a_statement_of_offence,
			icon: constatInfractionIcon
		},
		{
			title: pageData.assets.OnlineServicePage_activities_online_registration,
			icon: activityGrayIcon
		},
		{
			title: pageData.assets.OnlineServicePage_communication_subscription,
			icon: abonnementIcon
		}
	]

	const openLink = (e) => {
		if (e.target.localName == 'a') {
			return
		}

		if (
			e.target.className.includes(pageUtils.classes.icon) ||
			e.target.localName == 'p' ||
			e.target.id == ''
		) {
			let targetElement = e.target.parentElement

			while (targetElement.id == '') {
				targetElement = targetElement.parentElement
			}

			window.open(
				document
					.querySelector(`#${targetElement.id}`)
					?.querySelector('a')
					?.getAttribute('href')!
			)
			return
		}

		window.open(
			document
				.querySelector(`#${e.target.id}`)
				?.querySelector('a')
				?.getAttribute('href')!
		)
	}

	useEffect(() => {
		document.querySelectorAll(`.${pageUtils.classes.card}`).forEach((el) => {
			el.querySelector('a')?.setAttribute('target', '_blank')
		})
	}, [])

	return (
		<>
			<PageTitle title={formatStrapiText(pageData?.title)} />
			<SectionTitle title={formatStrapiText(pageData?.title)} />
			<section className={pageUtils.classes.section}>
				<div className={pageUtils.classes.container}>
					<div className={pageUtils.classes.column1}>
						{cards.map((card, index) => (
							<div
								key={`c-${index}`}
								id={`c-${index}`}
								className={pageUtils.classes.card}
								onClick={openLink}
							>
								<div className={pageUtils.classes.iconContainer}>
									<Icon src={card.icon} className={pageUtils.classes.icon} />
								</div>
								<Text key={`c-${index}`} content={card.title} />
							</div>
						))}
					</div>
					<div className={pageUtils.classes.column2}>
						<div className={pageUtils.classes.box}>
							<div
								style={{ marginBottom: '-10px' }}
								className={pageUtils.classes.secondaryColorBox}
							>
								{formatStrapiText(pageData?.assets.sidebar_important_links)}
							</div>
							<BlueBulletsList strapyLink labels={labels} />
						</div>
						<div
							className={`${pageUtils.classes.secondaryColorBox} ${pageUtils.classes.boxQuestion}`}
						>
							<p>
								{formatStrapiText(
									pageData.assets?.faqPage_cant_find_what_youre_looking_for
								)}
							</p>
							<Link
								to="/auth/requests/create/other-subject"
								className={pageUtils.classes.buttonOutline}
								onClick={onNavigateToOtherSubjectRequest()}
							>
								{formatStrapiText(pageData.assets?.label_write_us)}
							</Link>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default OnlineService
